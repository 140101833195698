/**
 *  MS-授权中心接口
 *  *********待删除******
 **/

import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/auth";

//单点登录-李腾飞
export const userLogin = (data, config = {}) => {
  return http.post(`${prefix}/user/login`, data, config);
};

// 获取授权token-云玉峰
export const userAuthorize = data => {
  return http.post(`${prefix}/user/authorize`, data);
};

// 退出登录-云玉峰
export const userLogout = data => {
  return http.post(`${prefix}/user/logout`, data);
};

// 创建公众号临时二维码-陈铭
export const wxQrcode = (data, config) => {
  return http.post(`${prefix}/wx/qrcode`, data, config);
};

// 获取用户是否关注公众号-陈铭
export const wxSubscribe = (data, config) => {
  return http.get(`${prefix}/wx/subscribe`, data, config);
};

// 通过openId查看用户是否为平台用户-陈铭
export const wxUser = data => {
  return http.get(`${prefix}/wx/user`, data);
};

// 保存微信用户openId与mobile(微信注册)-陈铭
export const wxUserOpenidMobile = (data, config) => {
  return http.post(`${prefix}/wx/user/openid/mobile`, data, config);
};

// 保存用户密码-陈铭
export const wxUserPw = (data, config) => {
  return http.post(`${prefix}/wx/user/pw`, data, config);
};
