/**
 *@name: 小程序扫码授权注册
 *@date: 2021-05-11
 *@author: 易远胜
 *@version: 3.0
 */

import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/small/application";

//【user005】生成顾问二维码-许家炜
export const wxGuideQrCode = (data, config = {}) => {
  return http.post(`${prefix}/wx/guide/qrcode`, data, config);
};
//【user007】轮询接口查看用户是否扫码-许家炜
export const wxSubScribe = (data, config = {}) => {
  return http.get(`${prefix}/wx/subscribe`, data, config);
};
//【user008】轮询接口返回token-许家炜
export const wxUserOpenIdMobile = (data, config = {}) => {
  return http.post(`${prefix}/wx/user/openid/mobile`, data, config);
};
