<template>
  <div class="login">
    <!--登录内容-->
    <main class="main">
      <!--密码登录-->
      <header class="header-title">
        <div class="flex-start" v-show="loginType !== 1">
          <h2
            :class="{ 'toggle-login': loginType === 3 }"
            @click="changeLoginType(2)"
          >
            密码登录
          </h2>
          <h2
            class="ml-2x"
            :class="{ 'toggle-login': loginType === 2 }"
            @click="changeLoginType(3)"
          >
            验证码登录
          </h2>
        </div>
        <h2 class="scan" v-show="loginType === 1">扫码登录</h2>
      </header>
      <!--切换扫码登录-->
      <div class="toggle-qrcode pointer">
        <!--<img-->
        <!--  src="../../assets/images/login-toggle-code.png"-->
        <!--  alt=""-->
        <!--  v-show="loginType !== 1"-->
        <!--  @click="changeLoginType(1)"-->
        <!--/>-->
        <!-- <i
          class="iconfont iconerweimadenglu"
          v-show="loginType !== 1"
          @click="changeLoginType(1)"
        ></i> -->
        <!--<img-->
        <!--  src="../../assets/images/login-toggle-pclogin.png"-->
        <!--  alt=""-->
        <!--  @click="changeLoginType(2)"-->
        <!--  v-show="loginType === 1"-->
        <!--/>-->
        <i
          class="iconfont iconmimadenglu"
          @click="changeLoginType(2)"
          v-show="loginType === 1"
        ></i>
      </div>
      <!--登录表单-->
      <a-form-model
        v-show="loginType !== 1"
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="ruleForm"
      >
        <a-form-model-item prop="mobile">
          <a-input
            v-model="loginForm.mobile"
            placeholder="请输入手机号码/账号"
            allow-clear
          ></a-input>
        </a-form-model-item>
        <a-form-model-item prop="password" class="mt-4x" v-if="loginType === 2">
          <a-input-password
            v-model="loginForm.password"
            placeholder="请输入密码"
            allow-clear
            @pressEnter="loginSubmit"
          ></a-input-password>
        </a-form-model-item>
        <a-form-model-item
          prop="validateCode"
          class="mt-4x"
          v-if="loginType === 3"
        >
          <a-input
            v-model="loginForm.validateCode"
            placeholder="请输入验证码"
            allow-clear
            @pressEnter="loginSubmit"
            type="number"
          >
            <a-button
              slot="suffix"
              type="link"
              v-if="time === 60"
              @click="getValidateCode('loginForm')"
            >
              <span class="font-l">获取验证码</span>
            </a-button>
            <span slot="suffix" class="font-l" v-else>
              <a-icon type="clock-circle" /> {{ time }}s
            </span>
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            size="large"
            class="res btn"
            @click="loginSubmit"
            :loading="loading"
            >登录</a-button
          >
        </a-form-model-item>
        <div
          @click="$router.push('/login/forgetPassword')"
          class="forget text-r"
        >
          <span class="blue font-xl">忘记密码</span>
        </div>
      </a-form-model>
      <!--二维码-->
      <div v-show="loginType === 1" class="qr-code">
        <a-spin :spinning="pending" tip="加载中...">
          <div class="relative">
            <img :src="qrUrl" alt="" class="img" />
            <div class="module" v-show="hadScan">
              <a-icon
                type="check-circle"
                class="primary el-icon-success"
                theme="filled"
              />
              <p class="font-l mt-1x">扫码成功</p>
            </div>
            <div class="module disabled" v-show="refresh && !pending">
              <a-icon
                type="close-circle"
                class="disabled el-icon-error"
                theme="filled"
              />
              <p class="font-l mt-1x">已失效</p>
            </div>
          </div>
        </a-spin>
        <!--未扫码-二维码失效-->
        <div v-show="!hadScan">
          <p class="font-l">
            <a-icon
              type="wechat"
              theme="filled"
              style="font-size: 18px; color: #09BB07"
              class="text-v"
            />
            <span class="text-v ml-1x">微信扫一扫</span>
          </p>
          <div
            class="flex-center align-center font-l red mt-x pointer"
            v-show="refresh && qrUrl && !pending"
            @click="getQrCode"
          >
            <p class="mr-x">二维码已失效，刷新</p>
            <a-icon type="reload" style="font-size: 18px" />
          </div>
          <p class="font-l mt-x" v-show="!refresh || pending">
            关注公众号注册登录
          </p>
        </div>
        <!--扫码成功-重新扫码-->
        <div v-show="hadScan">
          <p class="font-l">
            <a-icon
              type="wechat"
              theme="filled"
              style="font-size: 18px; color: #09BB07"
              class="text-v"
            />
            <span class="text-v ml-1x">小程序一键登录</span>
          </p>
          <div
            class="flex-center align-center font-l blue mt-x pointer"
            @click="reScan"
          >
            <p class="mr-x">重新扫码</p>
            <a-icon type="reload" style="font-size: 18px" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { loginMessageVerifyCode } from "../../api/message";
import { login, authorize, smsCodeLogin } from "../../api/user";
import { phoneReg, checkNormal } from "../../utils/global";
import { encrypt } from "../../utils/crypt-aes";
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import {
  wxGuideQrCode,
  wxUserOpenIdMobile,
  wxSubScribe
} from "../../api/applet";
export default {
  name: "Login",
  data() {
    return {
      loginType: 2,
      loading: false, // 表单提交中
      time: 60, // 验证码倒计时
      timerOut: null, //二维码失效一次性定时器
      timerOut2: null, //扫码二维码轮询结果一次性定时器
      timerIn: null, // 验证码定时器
      // 扫码登录
      scenStr: "", // 扫码登录的随机数
      qrUrl: "",
      pending: false, // 二维码加载中
      refresh: true, // 刷新二维码
      hadScan: false, // 是否已经扫码
      openId: "",

      // 登录表单
      loginForm: {
        mobile: "",
        password: "",
        validateCode: ""
      },
      loginRules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkNormal, trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              !/^[0-9]{6}$/.test(value)
                ? callback(new Error("验证码为6位数字"))
                : callback();
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    // this.getQrCode();
    console.log("this.$route", this.$route);
    if (this.$route.query) {
      let query = this.$route.query;
      if (query.redirect && query.redirect.indexOf("noCash=true") >= 0) {
        localStorage.setItem("noCash", 1); //无现金支付 - 2023.4.6长白山需要控制某些 无现金支付方式
      } else if (query.noCash && query.noCash == "true") {
        localStorage.setItem("noCash", 1); //无现金支付
      } else {
        localStorage.setItem("noCash", 0);
      }
    }
  },
  beforeDestroy() {
    this.refresh = true;
    this.clearTimerIn();
  },
  methods: {
    // 切换登录方式
    changeLoginType(type) {
      this.clearTimerOut();
      this.loginType = type;
      this.hadScan = false;
      this.refresh = true;
      if (type === 1) this.getQrCode();
      this.$refs.loginForm.clearValidate();
    },
    // 获取二维码
    getQrCode() {
      if (this.refresh) {
        this.pending = true;
        this.scenStr = new Date().getTime() + Math.random() + ""; // 随机数
        wxGuideQrCode(
          {
            qrcode_info: this.scenStr
          },
          {
            loading: false
          }
        )
          .then(({ qrUrl }) => {
            this.qrUrl = qrUrl;
            this.refresh = false;
            this.scanned();
            this.setTimerOut();
          })
          .catch(() => {})
          .finally(() => {
            this.pending = false;
          });
      }
    },
    // 设置一次性定时器
    setTimerOut() {
      this.timerOut = setTimeout(() => {
        this.refresh = true;
        this.clearTimerOut();
      }, 60000);
    },
    // 清除一次性定时器
    clearTimerOut() {
      clearTimeout(this.timerOut);
      clearTimeout(this.timerOut2);
      this.timerOut = null;
      this.timerOut2 = null;
    },
    // 重新扫码
    reScan() {
      this.hadScan = false;
      this.refresh = true;
      this.clearTimerOut();
      this.getQrCode();
    },
    // 获取是否扫码关注微信公众号
    scanned() {
      wxSubScribe(
        {
          scene_str: this.scenStr,
          t: new Date().getTime()
        },
        { loading: false }
      )
        .then(({ openId }) => {
          if (openId) {
            this.hadScan = true;
            this.getWxLogin(openId);
          }
          // 如果二维码没有失效且定时器不存在,因为频繁切换登录方式，网络延迟，会存在多过定时器
          else if (!this.refresh && !this.timerOut2) {
            this.timerOut2 = setTimeout(() => {
              this.scanned();
              clearTimeout(this.timerOut2);
              this.timerOut2 = null;
            }, 2000);
          }
        })
        .catch(() => {});
    },
    // 获取是否注册成功，成功则头部header得到token
    getWxLogin(openId) {
      wxUserOpenIdMobile({ openId }, { loading: false })
        .then(({ code }) => {
          if (code !== "FAIL") {
            this.linkTo(code);
          } else if (!this.refresh && this.hadScan) {
            this.timerOut2 = setTimeout(() => {
              this.getWxLogin(openId);
              clearTimeout(this.timerOut2);
              this.timerOut2 = null;
            }, 1000);
          }
        })
        .catch(() => {});
    },
    // 获取验证码
    getValidateCode(form) {
      if (!phoneReg.test(this[form].mobile)) {
        this.$message.warning("请输入正确的手机号");
        return;
      }
      loginMessageVerifyCode({
        verifyCodeType: "LOGIN",
        objectPhone: this[form].mobile
      })
        .then(() => {
          this.setTimerIn();
        })
        .catch(() => {});
    },
    // 验证码定时器
    setTimerIn() {
      this.time--;
      this.timerIn = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          this.clearTimerIn();
        }
      }, 1000);
    },
    // 去除二维码失效和扫码轮询结果定时器
    clearTimerIn() {
      clearInterval(this.timerIn);
      this.timerIn = null;
      this.time = 60;
    },
    // 登录按钮提交
    loginSubmit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // 密码登录
          if (this.loginType === 2) {
            this.loading = true;
            // 加密处理，获得加密后的秘钥和密文
            const { key, encryptedWord } = encrypt(this.loginForm.password);
            login(
              {
                phone: this.loginForm.mobile,
                password: encryptedWord,
                passwordKey: key
              },
              { loading: false }
            )
              .then(() => {
                this.linkTo(this.loginForm.mobile);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            // 验证码登录
            smsCodeLogin({
              phone: this.loginForm.mobile,
              code: this.loginForm.validateCode
            })
              .then(() => {
                this.linkTo(this.loginForm.mobile);
              })
              .catch(() => {
                this.loading = false;
              });
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },
    // 跳转到首页
    linkTo(account) {
      this.refresh = true; // 停止刷新
      this.clearTimerIn();
      /**
       *@name:跳转方法
       *@param: { String } url要跳转的路由，默认选择平台页面
       */
      const go = (url = "/login/selectScenic") => {
        this.loading = false;
        localStorage.setItem("account", account);
        this.$message.success("登录成功");
        this.$router.replace(url);
      };
      // 如果登录过选择过景区id
      if (localStorage.merchantId) {
        findCurrentUserRelationMerchantList()
          .then(res => {
            const userAuthorize = (merchantId, channel) => {
              authorize({
                merchantId,
                channel
              })
                .then(() => {
                  // 重新登录返回原来的页面，选择菜单没有处理，暂时注释
                  if (this.$route.query.redirect) {
                    // 返回上一个页面
                    go(this.$route.query.redirect);
                  } else {
                    go();
                  }
                })
                .catch(() => {
                  go();
                });
            };
            // 判断该账户是否存在该景区
            let temp = res.find(
              item => item.merchantId === localStorage.merchantId
            );
            if (temp) {
              localStorage.setItem("userId", temp.userId);
              // 跳转之前选择的景区
              userAuthorize(localStorage.merchantId, 6);
            } else if (res.length === 1) {
              // 只有一个景区直接跳转该景区
              userAuthorize(res[0].merchantId, 6);
              localStorage.setItem("userId", res[0].userId);
            } else {
              go();
            }
          })
          .catch(() => {});
      }
      // 新用户，直接跳转选择平台页面
      else {
        localStorage.removeItem("merchantId");
        localStorage.removeItem("channel");
        go();
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom";
.login {
  position: relative;
  height: 100vh;
  background: url("../../assets/images/login-bg.png");
  background-size: 100% 100%;
  .main {
    width: 500px;
    height: 500px;
    box-sizing: border-box;
    padding: 32px 32px 32px;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;

    .header-title {
      h2 {
        font-weight: normal;
        font-size: 28px;
      }
      h2:not(.scan):hover {
        cursor: pointer;
        color: @primary;
      }
      .toggle-login {
        padding-bottom: 4px;
        align-self: flex-end;
        font-weight: 400;
        font-size: 16px;
        color: @gray;
      }
    }

    .toggle-qrcode {
      position: absolute;
      top: 28px;
      right: 32px;
      .iconfont {
        font-size: 48px;
        color: @primary;
      }
      /*img {*/
      /*  width: 48px;*/
      /*  height: 48px;*/
      /*}*/
    }
    /*登录表单*/
    .ruleForm {
      margin-top: 36px;
      padding-top: 32px;
      box-sizing: border-box;
      /deep/.ant-input {
        height: 50px;
        padding: 0;
        border-radius: unset;
        border-top: none;
        border-left: none;
        border-right: none;
        /*border-width: 2px;*/
        font-size: 18px;
        &:focus {
          box-shadow: none;
        }
      }
      .btn {
        margin-top: 48px;
        height: 48px;
        font-size: 18px;
      }
    }
    /*二维码*/
    .qr-code {
      margin-top: 24px;
      text-align: center;
      .img {
        width: 300px;
        height: 300px;
      }
      .module {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.98);
        .el-icon-success,
        .el-icon-error {
          margin-top: 90px;
          font-size: 100px;
        }
      }
    }
  }
}
.forget {
  margin-top: 57px;
  span {
    cursor: pointer;
    padding: 10px;
  }
}
.forget1 {
  span {
    cursor: pointer;
    padding: 10px;
  }
}
</style>
