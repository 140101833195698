<!--选择景区（平台）-->
<template>
  <div class="select-container">
    <div class="content">
      <header class="header bd-b mb-3x">
        <div class="flex-start align-center">
          <p class="bold primary font-xxl">售票窗口</p>
          <p class="split bd-r"></p>
          <p class="font-xl">选择景区</p>
        </div>
        <a-dropdown :trigger="['click']">
          <div class="flex-start align-center blue pointer">
            <div class="avatar">
              <img
                :src="userInfo.headImgUrl"
                alt=""
                v-if="userInfo.headImgUrl"
              />
              <a-avatar :size="40" icon="user" v-else />
            </div>
            <div class="mh-1x">
              <p class="font-s">{{ userInfo.nickName }}</p>
              <p>{{ account | safeShow }}</p>
            </div>
            <a-icon type="down" style="font-size: 16px" />
          </div>
          <a-menu slot="overlay" @click="handleCommand">
            <a-menu-item :key="2">修改密码</a-menu-item>
            <a-menu-item :key="3">退出登录</a-menu-item>
          </a-menu>
        </a-dropdown>
      </header>
      <!--列表-->
      <a-row :gutter="[24, 24]">
        <a-col
          :lg="{ span: 12 }"
          :xl="{ span: 8 }"
          v-for="(item, index) in list"
          :key="index"
          @click="linkTo(item)"
        >
          <div class="card">
            <div class="flex-between align-center">
              <h2 class="normal text-1">{{ item.merchantName }}</h2>
              <p class="font-l primary">{{ item.merchantTypeText }}</p>
            </div>
            <p class="mt-4x font-l gray" v-if="item.merchantExpireTime">
              创建日期：{{ item.createTime | date }}
            </p>
            <p class="mt-2x font-l gray" v-if="item.merchantExpireTime">
              到期日期：{{ item.merchantExpireTime | date }}
            </p>
            <i class="iconfont iconliangting links-icon"></i>
          </div>
        </a-col>
        <a-col :lg="{ span: 12 }" :xl="{ span: 8 }" v-if="noData">
          <div class="card pointer text-c gray add">
            <span style="font-size: 24px; line-height: 132px"
              >暂无可操作景区</span
            >
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import { authorize, getUserInfo, logout } from "../../api/user";
import { phoneFormat } from "../../utils/global";
export default {
  name: "SelectScenic",
  data() {
    return {
      account: localStorage.getItem("account"),
      userInfo: {},
      list: [],
      noData: false,
      dialogVisible1: false, // 修改手机号弹窗
      dialogVisible2: false // 修改密码弹窗
    };
  },
  created() {
    this.loadData();
    this.loadUser();
  },
  filters: {
    safeShow: phoneFormat
  },
  methods: {
    // 加载组织列表
    loadData() {
      findCurrentUserRelationMerchantList()
        .then(res => {
          // merchantType-商户类型：1趣买票，2服务商（趣买票代理商），3集团，4景区，5游小铺，6园内商户，7周边商户，8分销商，9OTA，10零售供应商
          this.list = res.filter(item => item.merchantType === 4);
          this.noData = this.list.length === 0;
        })
        .catch(() => {});
    },
    // 加载用户信息
    loadUser() {
      getUserInfo()
        .then(res => {
          this.userInfo = res;
          if (!res.ifSetPwd) this.$router.push("/login/ChangePassword");
        })
        .catch(() => {});
    },
    handleCommand({ key }) {
      if (key === 1) {
        this.dialogVisible1 = true;
      } else if (key === 2) {
        // this.dialogVisible2 = true;
        this.$router.push("/login/ChangePassword");
      } else {
        this.$confirm({
          title: "提示",
          content: "确定要退出登录吗？",
          onOk: () => {
            logout()
              .then(() => {
                this.$message.success("已退出登录");
              })
              .catch(() => {})
              .finally(() => {
                localStorage.removeItem("toke");
                localStorage.removeItem("account");
                this.$router.push("/login");
              });
          }
        });
      }
    },
    // 跳转到首页
    linkTo(item) {
      authorize({
        merchantId: item.merchantId,
        channel: 6 // 窗口渠道6
      })
        .then(() => {
          localStorage.setItem("merchantId", item.merchantId); // 商户id，下次登录不用选择平台
          localStorage.setItem("userId", item.userId); // 用户id，获取用户信息
          this.$router.push("/");
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom.less";
.select-container {
  height: 100%;
  overflow-y: auto;
  background: #fff;

  .content {
    max-width: 1300px;
    box-sizing: border-box;
    margin: 0 auto;
    padding-bottom: 24px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      left: 0;
      padding: 24px 0 16px;
      background: #fff;
      z-index: 2;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: #f7f7f7;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .split {
        height: 24px;
        margin: 0 16px;
      }
    }

    .card {
      height: 185px;
      position: relative;
      padding: 24px;
      border-top: 4px solid @primary;
      border-right: 1px solid @border;
      border-bottom: 1px solid @border;
      border-left: 1px solid @border;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
      }

      .links-icon {
        position: absolute;
        right: 24px;
        bottom: 24px;
        font-size: 32px;
        color: @primary;
      }
      /*添加体验用户*/
      &.add {
        border: 1px solid @border;
      }
    }
  }
}
</style>
