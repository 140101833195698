<template>
  <div class="root">
    <!-- 重置密码表单 -->
    <div class="formpsd">
      <!-- 头部 start -->
      <div class="title">
        <span>忘记密码</span>
        <a-icon
          @click="$router.back()"
          class="font-xl close pointer gray"
          type="close"
        />
      </div>
      <!-- 头部 end -->
      <!-- 表单部分 start -->
      <a-form-model
        ref="formRef"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        :rules="loginRules"
        :model="loginForm"
      >
        <a-form-model-item label="新密码" prop="newpass">
          <a-input
            v-model="loginForm.newpass"
            size="large"
            placeholder="请输入密码"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="oldpass">
          <a-input v-model="loginForm.oldpass" size="large" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input
            v-model="loginForm.phone"
            size="large"
            placeholder="请输入手机号"
          />
        </a-form-model-item>
        <a-form-model-item label="验证码">
          <a-input-search
            v-model="loginForm.value"
            placeholder="请输入验证码"
            :enter-button="text"
            @search="getCode"
            size="large"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loginFn" class="res btn"
            >修改</a-button
          >
        </a-form-model-item>
      </a-form-model>
      <!-- 表单部分 end -->
    </div>
  </div>
</template>

<script>
import { loginMessageVerifyCode } from "../../api/message";
import { restPassword, smsCodeLogin } from "../../api/user";
import { checkNormal } from "../../utils/global";
import { encrypt } from "../../utils/crypt-aes";
var times;
export default {
  name: "forgetPassword",
  data() {
    return {
      text: "获取验证码",
      loginForm: {
        newpass: "",
        value: "",
        oldpass: "",
        phone: ""
      },
      loginRules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: checkNormal, trigger: "blur" }
        ],
        newpass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "密码长度因在 6 - 12为之间",
            trigger: "blur"
          }
        ],
        oldpass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "密码长度因在 6 - 12为之间",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    //   点击登录 校验 各个表单
    loginFn() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.loginForm.oldpass !== this.loginForm.newpass) {
            this.$message.warning("密码不一致");
            return;
          }
          smsCodeLogin({
            phone: this.loginForm.phone,
            code: this.loginForm.value
          })
            .then(() => {
              this.rest();
            })
            .catch(() => {});
        }
      });
    },
    // 重置密码方法
    rest() {
      // 加密处理，获得加密后的秘钥和密文
      const { key, encryptedWord } = encrypt(this.loginForm.oldpass);
      restPassword({
        phone: this.loginForm.phone,
        newPwdKey: key,
        newPwd: encryptedWord
      }).then(() => {
        this.$message.success("密码重置成功 请重新登录");
        this.close();
        this.$router.back();
      });
    },
    // 清理定时器
    close() {
      clearInterval(times);
      times = null;
    },
    // 获取验证码
    getCode() {
      if (Number(this.text)) return;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.text == "获取验证码" || this.text == "重新获取") {
            this.text = 60;
          }
          times = setInterval(() => {
            this.timeFN();
          }, 1000);
          loginMessageVerifyCode({
            verifyCodeType: "LOGIN",
            objectPhone: this.loginForm.phone
          }).then(() => {});
        }
      });
    },
    //   倒计时
    timeFN() {
      this.text--;
      if (this.text < 10) {
        this.text = "0" + this.text;
      }
      if (Number(this.text) < 1) {
        this.text = "重新获取";
        this.close();
      }
    }
  },
  //   组件卸载 清理定时器
  beforeDestroy() {
    this.close();
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
.root {
  height: 100vh;
  background-color: #f1f1f1;
  position: relative;
  .formpsd {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 380px;
    height: 380px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 10px 10px 10px 10px;
    .title {
      padding: 17px 0;
      display: flex;
      align-items: center;
      span {
        margin-left: 153px;
        font-size: 18px;
      }
      .close {
        margin-left: 113px;
      }
    }
    .btn {
      width: 200px;
      height: 32px;
      border-radius: 16px 16px 16px 16px;
      margin-left: 89px;
    }
  }
}
/deep/.ant-input-group-addon {
  .ant-btn {
    width: 120px;
  }
}
</style>
