<template>
  <div class="container">
    <header class="flex-between mb-3x">
      <h1 class="title">修改密码</h1>
      <div>
        <span class="mr-2x">{{ userInfo.phone | safeShow }}</span>
        <span class="mr-2x pointer" @click="selectScenic">选择景区</span>
        <span class="pointer" @click="handleCommand">退出</span>
      </div>
    </header>
    <div class="main">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 7 }"
      >
        <a-form-model-item
          label="原密码"
          prop="oldPassword"
          :colon="false"
          v-if="userInfo.ifSetPwd"
        >
          <a-input-password
            class="ml-2x"
            v-model="form.oldPassword"
            placeholder="请输入原密码"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPassword" :colon="false">
          <a-input-password
            class="ml-2x"
            v-model="form.newPassword"
            placeholder="请输入新密码"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="surePassword" :colon="false">
          <a-input-password
            class="ml-2x"
            v-model="form.surePassword"
            placeholder="请确认密码"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ offset: 9 }">
          <a-button type="primary" @click="onSubmit"> 确认修改 </a-button>
          <a-button class="ml-2x" @click="$router.back()"> 返回 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { userLogout } from "../../api/authorization";
import { checkText, phoneFormat } from "../../utils/global";
import { getUserInfo, setPassword } from "@/api/user";
import { encrypt } from "../../utils/crypt-aes";
export default {
  name: "ChangePassword",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        surePassword: ""
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            validator: checkText,
            trigger: "blur"
          }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 8, max: 16, message: "密码长度在8~16位之间", trigger: "blur" },
          {
            validator: checkText,
            trigger: "blur"
          }
        ],
        surePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 8, max: 16, message: "密码长度在8~16位之间", trigger: "blur" },
          {
            validator: checkText,
            trigger: "blur"
          },
          {
            validator: this.validatePasswordISOK,
            trigger: "blur"
          }
        ]
      },
      isClick: false,
      userInfo: {} // 是否设置过密码
    };
  },
  created() {
    this.loadUser();
  },
  filters: {
    safeShow: phoneFormat
  },
  methods: {
    // 加载用户信息
    loadUser() {
      getUserInfo()
        .then(res => {
          this.userInfo = res;
        })
        .catch(() => {});
    },
    validatePasswordISOK(rule, value, callback) {
      let { newPassword, surePassword } = this.form;
      if (newPassword !== surePassword) {
        callback(new Error("新密码与确认密码不一致"));
      } else {
        callback();
      }
    },
    // 选择景区
    selectScenic() {
      this.$router.push("/login/selectScenic");
    },
    // 退出登录
    handleCommand() {
      this.$confirm({
        title: "提示",
        content: "确定要退出登录吗？",
        onOk: () => {
          userLogout()
            .then(() => {
              this.$message.success("已退出登录");
            })
            .catch(() => {})
            .finally(() => {
              this.toLogin();
            });
        }
      });
    },
    // 去登录
    toLogin() {
      localStorage.removeItem("toke");
      localStorage.removeItem("account");
      this.$router.replace("/login");
    },
    // 修改密码
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          getUserInfo().then(res => {
            let { phone } = res;
            let { oldPassword, newPassword } = this.form;
            // 加密
            const obj1 = encrypt(oldPassword);
            const obj2 = encrypt(newPassword);
            setPassword({
              phone,
              oldPwdKey: obj1.key,
              oldPwd: obj1.encryptedWord,
              newPwdKey: obj2.key,
              newPwd: obj2.encryptedWord
            }).then(() => {
              this.$confirm({
                title: "提示",
                content: "修改成功，是否重新登录？",
                okText: "重新登录",
                cancelText: "返回上一页",
                onOk: () => {
                  this.toLogin();
                },
                onCancel: () => {
                  this.$router.back();
                }
              });
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom.less";
.container {
  max-width: 1200px;
  box-sizing: border-box;
  padding: 24px;
  margin: 0 auto;

  .main {
    box-sizing: border-box;
    border-top: 15px solid @bgGray;
    border-bottom: 15px solid @bgGray;
    border-left: 10px solid @bgGray;
    border-right: 10px solid @bgGray;
    padding: 60px 0;
    .send-code {
      position: absolute;
      right: -100px;
      top: -12px;
    }
  }
}
</style>
